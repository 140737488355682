import { render, staticRenderFns } from "./PageDeveloperCouponAppliedReport.vue?vue&type=template&id=1f06d46f&"
import script from "./PageDeveloperCouponAppliedReport.vue?vue&type=script&lang=js&"
export * from "./PageDeveloperCouponAppliedReport.vue?vue&type=script&lang=js&"
import style0 from "./PageDeveloperCouponAppliedReport.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VContainer,VSkeletonLoader,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/tp-vision-web1-vuetify2/tp-vision-web1-vuetify2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f06d46f')) {
      api.createRecord('1f06d46f', component.options)
    } else {
      api.reload('1f06d46f', component.options)
    }
    module.hot.accept("./PageDeveloperCouponAppliedReport.vue?vue&type=template&id=1f06d46f&", function () {
      api.rerender('1f06d46f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/developer/PageDeveloperCouponAppliedReport.vue"
export default component.exports