<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-snackbar
                v-model="showPopup"
                :timeout="timeout"
                color="success"
                top
                center
                >
                {{ this.message }}
            </v-snackbar>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            class="sticky-table"
            :items="data"
            :search="search">
            <template v-slot:header="">
                <thead>
                <tr>
                    <th v-for="(header, index) in dataHeader" :key="index" :class="index === 0 ? 'sticky-column' : ''">
                    {{ header.text }}
                    </th>
                </tr>
                </thead>
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    Coupon Applied
                                </v-toolbar-title>
                            </v-row>
                            <AFilterWithoutRefresh
                                :filterHeaders="dataHeader"
                                :data="data"
                                @reset="(response)=>{
                                    data = response
                                }"
                                @filter="(response)=> {
                                    data = response
                                }"
                                @refresh="fetch"/>
                                <div class="d-flex justify-start" >
                                    <v-btn
                                        color="primary"
                                        style="margin-top:-68px"
                                        small
                                        @click="downloadReport"
                                        plain>
                                        Download Report
                                    </v-btn>
                                </div>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:body="{items}">
            <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td class="sticky-column"
                    @click="copyText(item.company_id)">
                        <ACopyAbleText :text="item.company_id"></ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.company_name)">
                        <ACopyAbleText :text="item.company_name">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.coupon)">
                        <ACopyAbleText :text="item.coupon">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.status)">
                        <ACopyAbleText :text="item.status">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.amount)">
                        <ACopyAbleText :text="item.amount">
                        </ACopyAbleText>
                    </td>
                    <td
                    @click="copyText(item.created_at)">
                        <ACopyAbleText :text="item.created_at" >
                        </ACopyAbleText>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../../components/common/ASuccessWithoutReload.vue';
import AFilterWithoutRefresh from '../../common/filter/AFilterWithoutRefresh.vue';
import excelUtils from '../../../utils/excelUtils';
    export default {
    components:{
    ASuccessWithoutReload,
    AFilterWithoutRefresh,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        showPopup:false,
        message:"Text copied succesfully",
        timeout:2000,
        data:[],
        dataHeader:[
            {
                text:'Company Id',
                value:'company_id'
            },
            
            {
                text:'Company name',
                value:'company_name'
            },
            {
                text:'Coupon',
                value:'coupon'
            },
            {
                text:"Status",
                value:"status"
            },
            {
                text:'Minutes',
                value:'amount',
            },
            {
                text:'Applied date',
                value:'created_at'
            },
            
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class === "GetCouponApplied") {
                
                let tempData =  this.processSearchResult(this.$_.clone(resp.data));
                this.data = tempData;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.$api.fetch(this.fetchCouponsApplied());
        },
        processSearchResult(data) {
            data.forEach(element => {
                if(element.created_at != null) {
                    element.created_at = this.$moment(element.created_at).format("YYYY-MM-DD HH:mm:ss");
                }
            })
            return data;
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function
        fetchCouponsApplied() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v7")+"/developer/coupon/applied";
            return tempApi;
        },
        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.showPopup = true;
        },
        handleClosePopup() {
            this.showPopup = false;
        },
        downloadReport() {
            try{
                let downloadData = null;
                if(this.data.length > 0) {
                    downloadData = this.data.map(item=>{
                        return {
                            "Company Id":item.company_id,
                            "Company name":item.company_name,
                            "Coupon":item.coupon,
                            "Minutes":item.amount,
                            "Applied date":item.created_at,
                        }
                    });
                }
                else {
                    downloadData = [
                        {
                            "Company Id":"",
                            "Company name":"",
                            "Coupon":"",
                            "Minutes":"",
                            "Applied date":"",
                        }
                    ]
                }
              excelUtils.exportToExcel(downloadData,"coupon_applied.xlsx");
            }catch(error) {
                alert(error)
            }
        }
    }
}
</script>
<style>
.sticky-table {
    overflow-x:scroll;
}
.sticky-column {
    position:sticky;
    left:0;
    z-index:1;
    background:#f8f9fa;
    border-right: 1px solid #ddd;
}
td {
    min-width:200px;
    text-align:center;
    font-family: Arial, sans-serif;
    border-right: 1px solid #ddd;
}
th {
    text-align:center!important;
    background:#f8f9fa;
    border-top:1px solid #ddd;
    border-right: 1px solid #ddd;
}

.v-data-footer {
    background-color:#f8f9fa;
}
</style>